import React from 'react'
import './main.scss'
import MainBlock from '../components/MainBlock'
import Header from '../components/header/Header'
import bg from '../img/Background.png'
const Main = () => {
  return (
    <>
      <div className="main-wrapper">
        {/* <div className="bg"> */}
        {/* <img src={bg} alt="" /> */}
        <MainBlock />
        {/* </div> */}
      </div>
    </>
  )
}

export default Main
