import React, { useState } from 'react'
import './MainBlock.scss'
import Header from './header/Header'
import bg from '../img/Background.png'
import mobile_bg from '../img/mobile-bg.png'
import tablet_bg from '../img/tablet-bg.png'

import twitter from '../img/icon/twitter.svg'
import facebook from '../img/icon/facebook.svg'
import instagram from '../img/icon/instagram.svg'
import useWindowSize from '../utils/resizeHook'
import ModalComingSoon from './ModalComingSoon/ModalComingSoon'

const MainBlock = () => {
  const { currentWidth } = useWindowSize()

  const isTablet = currentWidth <= 768
  const isMobile = currentWidth <= 430
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="bg-wrapper">
      <img
        className="bg-images"
        src={isMobile ? mobile_bg : isTablet ? tablet_bg : bg}
        alt=""
      />
      <Header setShowModal={setShowModal} />

      <div>
        <div className="block_wrapper">
          <p className="title">
            The Ultimate User-Friendly DeFi Hub on <span>Base</span> and{' '}
            <span>Optimism</span> Networks
          </p>
          <p className="sub_text">
            Discover a comprehensive ecosystem featuring a DEX, launchpad,
            cross-chain exchange, lottery, and more, all designed to elevate
            your DeFi experience.
          </p>
          <div className="button_wrapper">
            <button
              className="mainBlock_button"
              onClick={() => setShowModal(true)}
            >
              Launch app
            </button>
          </div>
          <div className="main-block">
            <div className="main-text">
              <span className="main-text__title">Get ready</span>
              <span className="main-text__text">
                DyaDEX is committed to embracing the synergy of the leading
                Ethereum Layer 2 networks, Optimism and Base. Acknowledged as
                twin networks, with Base having been modeled on Optimism, we
                leverage the capabilities of these premier Optimistic Rollups.
                This alignment fosters a forward-thinking and balanced approach
                to DeFi solutions for our users. Furthermore, DyaDEX is designed
                to encourage collaboration and interaction between users of both
                networks, creating a seamless ecosystem where they can feed into
                and benefit from each other.
              </span>
            </div>
          </div>
          <div className="social-link-block">
            <a
              className="icon"
              href="https://twitter.com/Dya_Dex"
              target="_blank"
            >
              <img className="iconImg" src={twitter} alt="twitter" />
            </a>
          </div>
        </div>
      </div>
      {showModal && <ModalComingSoon setShowModal={setShowModal} />}
    </div>
  )
}

export default MainBlock
