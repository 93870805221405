import React, { useState } from 'react'
import './ModalComingSoon.scss'
import modal_bg from '../../img/modal-bg.png'
interface Props {
  setShowModal: (a: boolean) => void
}
const ModalComingSoon: React.FC<Props> = ({ setShowModal }) => {
  return (
    <div className="main-coming-soon-wrapper">
      <div className="coming-soon-wrapper">
        <div className="coming-soon-wrapper__content">
          <span className="coming-soon-wrapper__title">Coming Soon</span>
          <button
            className="coming-soon-wrapper__button"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
export default ModalComingSoon
