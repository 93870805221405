import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import logo from './logo.svg'
import './App.css'
import Main from './page/main'

function App() {
  return (
    <>
      <div>
        <Main />
      </div>
    </>
  )
}

export default App
