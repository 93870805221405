import React from 'react'
import './Header.scss'
import logo from '../../img/main-logo.svg'
import logoMobile from '../../img/mobile-logo.svg'
import useWindowSize from '../../utils/resizeHook'

interface Props {
  setShowModal: (a: boolean) => void
}

const Header: React.FC<Props> = ({ setShowModal }) => {
  const { currentWidth } = useWindowSize()

  const isTablet = currentWidth < 1140

  return (
    <div className="wrapper">
      <div className="logo_wrapper">
        <img src={isTablet ? logoMobile : logo} alt="" />
      </div>

      <div className="button_wrapper">
        <button className="button" onClick={() => setShowModal(true)}>
          Launch App
        </button>
      </div>
    </div>
  )
}

export default Header
